import React from 'react';
import { Avatar } from '@mui/material';

import { gql, useQuery } from '@apollo/client';

const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      fullName
      firstName
      lastName
      email
      image
    }
  }
`;

interface UserAvatarProps {
  userId?: string;
  height?: number;
  fontSize?: number;
}

export const UserAvatar = ({
  userId,
  height = 35,
  fontSize = 20,
}: UserAvatarProps) => {
  const { data } = useQuery(GET_USER, {
    variables: { id: userId },
    skip: !userId,
    fetchPolicy: 'cache-first',
  });

  const initials = React.useMemo(() => {
    if (!data?.user) {
      return '';
    }
    let initials = '';
    if (data?.user.firstName) {
      initials += data?.user.firstName[0];
    }
    if (data?.user.lastName) {
      initials += data?.user.lastName[0];
    }
    if (data?.user.fullName && !initials) {
      initials = data?.user.fullName[0];
    }

    return initials;
  }, [data]);

  if (!userId) {
    return null;
  }

  return (
    <Avatar
      src={data?.user?.image}
      alt={data?.user?.fullName}
      title={data?.user?.fullName}
      sx={{
        height: height,
        width: height,
        fontSize: fontSize,
        fontWeight: 700,
        backgroundColor: data?.user?.image ? 'white' : 'primary.pink',
      }}
    >
      {initials}
    </Avatar>
  );
};
