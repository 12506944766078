import React, { useEffect, useState } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import { ContactItem } from '../../../components/ContactItem';
import { PlannerDrawer } from '../../../components/PlannerDrawer';
import { SearchContact } from './SearchContact';
import { enqueueSnackbar } from 'notistack';
import { useEventContacts } from '../../../hooks/useEventContactsQuery';
import { useEvent } from '../../../providers/event';
import { useMutation, gql } from '@apollo/client';
import {
  EventContactsQuery,
  EventContactsQueryVariables,
  CreateAdditionalContactMutation,
  CreateAdditionalContactMutationVariables,
} from '@pv/common/graphql/operations';
import { eventContactsQuery } from '../../../hooks/useEventContactsQuery';

const createAdditionalContactMutation = gql`
  mutation CreateAdditionalContact($input: CreateAdditionalContactInput!) {
    createAdditionalContact(input: $input) {
      planner {
        id
        fullName
        email
        phone
        account {
          name
        }
      }
    }
  }
`;

export const ContactArea = ({ readOnly = false }: { readOnly: boolean }) => {
  const { event } = useEvent();
  const eventId = event?.id;

  const [showAdditionalContacts, setShowAdditionalContacts] = useState(false);
  const [openPlannerDrawer, setOpenPlannerDrawer] = useState(false);
  const [showAddContact, setShowAddContact] = useState(false);
  const [prefilledFirstName, setPrefilledFirstName] = useState('');

  useEffect(() => {
    setPrefilledFirstName('');
  }, [showAddContact]);

  const { planner, additionalContacts } = useEventContacts();

  const [createAdditionalContact] = useMutation<
    CreateAdditionalContactMutation,
    CreateAdditionalContactMutationVariables
  >(createAdditionalContactMutation, {
    onCompleted: () => {
      setShowAddContact(false);
      enqueueSnackbar('Contact added', { variant: 'success' });
    },
    update: (cache, { data }) => {
      if (!eventId || !data || !data.createAdditionalContact) {
        return;
      }

      const existingContacts = cache.readQuery<
        EventContactsQuery,
        EventContactsQueryVariables
      >({
        query: eventContactsQuery,
        variables: { eventId },
      });

      if (!existingContacts || !existingContacts.event) {
        return;
      }

      cache.writeQuery({
        query: eventContactsQuery,
        variables: { eventId },
        data: {
          event: {
            ...existingContacts.event,
            additionalContacts: [
              ...existingContacts.event.additionalContacts,
              data.createAdditionalContact.planner,
            ],
          },
        },
      });
    },
  });

  if (!planner) {
    return null;
  }

  return (
    <Stack gap={2} padding={2} data-cy="event-page-contacts">
      <ContactItem
        id={planner.id}
        fullName={planner.fullName || ''}
        email={planner.email || ''}
        phone={planner.phone || ''}
        accountName={planner.account?.name || ''}
        readOnly={readOnly}
        canRemove={additionalContacts.length > 0}
        primaryContactId={planner.id}
        firstAdditionalContactId={additionalContacts[0]?.id}
      />

      {showAdditionalContacts &&
        additionalContacts.map((contact) => (
          <React.Fragment key={contact.id}>
            <Divider />
            <ContactItem
              id={contact.id}
              fullName={contact.fullName || ''}
              email={contact.email || ''}
              phone={contact.phone || ''}
              accountName={contact.account?.name || ''}
              canRemove={true}
              primaryContactId={planner.id}
              readOnly={readOnly}
              firstAdditionalContactId={additionalContacts[0]?.id}
            />
          </React.Fragment>
        ))}

      {showAddContact && (
        <>
          <Divider />
          {eventId && (
            <SearchContact
              onClickSearchResult={(plannerId) => {
                setShowAdditionalContacts(true);
                createAdditionalContact({
                  variables: {
                    input: {
                      eventId,
                      plannerId,
                    },
                  },
                });
              }}
              onClickAddContact={(firstName) => {
                setPrefilledFirstName(firstName);
                setOpenPlannerDrawer(true);
              }}
              eventId={eventId}
            />
          )}
        </>
      )}

      <Stack direction="row" justifyContent="space-between">
        {!readOnly && (
          <Button
            onClick={() => {
              setShowAddContact(!showAddContact);
            }}
          >
            {showAddContact ? 'Cancel' : 'Add Contact'}
          </Button>
        )}
        {additionalContacts.length > 0 && (
          <Button
            onClick={() => {
              setShowAdditionalContacts(!showAdditionalContacts);
            }}
          >
            {showAdditionalContacts
              ? 'Hide'
              : `Show All (${additionalContacts.length})`}
          </Button>
        )}
      </Stack>
      <PlannerDrawer
        open={openPlannerDrawer}
        onClose={() => {
          setOpenPlannerDrawer(false);
          setShowAddContact(false);
        }}
        prefilledFirstName={prefilledFirstName}
        eventId={eventId}
      />
    </Stack>
  );
};
